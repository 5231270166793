import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { data } from '../components/cheats-data.js';
import { Games } from '../components/games.js';
import PopupButton from '../components/Iframe.jsx'
import stripe from '../images/stripe.webp';

export const Product = () => {


  const { gameUrl } = useParams();
  const { cheatUrl } = useParams();

  const [activeIndex, setActiveIndex] = useState(0);


  const sanitizeForURL = (str) => {
    // Regular expression to match any character that is not a letter, number, underscore, or hyphen
    const invalidCharRegex = /[^a-zA-Z0-9_\-]/g;

    // Replace invalid characters with an empty string
    return str.replace(invalidCharRegex, '');
  };


  const getGame = () => {
    const verifiedGame = Games.find((game) => sanitizeForURL(game.game) === gameUrl);

    if (verifiedGame != null) {
      return verifiedGame.game;
    } else {
      return null;
    }
  }

  const getCheat = () => {
    const verifiedCheat = data.find((cheat) => cheat.game === getGame() && sanitizeForURL(cheat.name) === cheatUrl);

    if (verifiedCheat != null) {

      return verifiedCheat.name;
    } else {
      return null;
    }

  }

  const getCheatOBJ = () => {
    const verifiedCheat = data.find((cheat) => cheat.game === getGame() && sanitizeForURL(cheat.name) === cheatUrl);

    if (verifiedCheat != null) {

      return verifiedCheat;
    } else {
      return null;
    }
  }


  const urlChecker = () => {
    return getGame() != null && getCheat() != null;
  };


  const [activeSelect, setActiveSelect] = useState(0);

  const toggleSelect = (index) => {
    setActiveSelect(index);
  };

  const removeSpaces = (str) => {
    return str.replace(/\s/g, '');
  }


  return (
    <div>
      <Helmet>‍
        <title>{getCheat()} {getGame()} cheat - Fivestar Solutions</title>
        <meta name="description" content={"Fivestar Solutions offers the best " + getGame() + " " + "cheats. These cheats include: " + data.filter((cheat) => cheat.game === getGame()).map((filteredCheat) => { return filteredCheat.name })} />
        <meta property="og:title" content={getCheat() + " " + getGame() + " cheat - Fivestar Solutions"} />
        <meta property="og:description" content={`Fivestar Solutions offers the best ${getGame()} cheats. These cheats include: ${data.filter((cheat) => cheat.game === getGame()).map((filteredCheat) => { return filteredCheat.name })}`} />
        <meta property="og:image" content={getCheatOBJ().image} />
        <meta name="twitter:image" content={getCheatOBJ().image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getCheat() + " " + getGame() + " cheat - Fivestar Solutions"} />
        <meta name="twitter:description" content={"Fivestar Solutions offers the best " + getGame() + " " + "cheat's. These cheats include: " + data.filter((cheat) => cheat.game === getGame()).map((filteredCheat) => { return filteredCheat.name })} />
      </Helmet>
      {urlChecker() > 0 ? (
        <>
          <div className="product-hero">
            <div className="product-media-container">
              <div className="product-media">
                {data.filter((cheat) => cheat.name === getCheat() && cheat.game === getGame()).map((filteredCheat) =>
                  filteredCheat.media.map((filteredMedia, index) => {
                    if (filteredMedia.url) {
                      return (
                        <div className={activeIndex === index ? "media-product  media-active" : "media-product"} key={index}><iframe src={filteredMedia.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
                      );
                    } else {
                      return <div className={activeIndex === index ? "media-product  media-active" : "media-product"} key={index}><img src={filteredMedia.img} /></div>
                    }
                  })
                )}
              </div>
              <div className="media-dots">
                {data.filter((cheat) => cheat.name === getCheat() && cheat.game === getGame()).map((filteredCheat) =>
                  filteredCheat.media.map((filteredMedia, index) => {
                    return <div onClick={() => setActiveIndex(index)} className={activeIndex === index ? "dot dot-active" : "dot"} key={index}></div>;
                  })
                )}
              </div>
            </div>
            <div className="product-info">
              <div className="info-container">
                <div className="info-stats">
                  <span className="game">GAME:  {data.filter((cheat) => cheat.name === getCheat() && cheat.game === getGame()).map((filteredCheat) => { return <span className={"game-" + sanitizeForURL(filteredCheat.game.toLowerCase())}>{filteredCheat.game.toUpperCase()}</span> })}</span>
                  <span className="status">STATUS:  {data.filter((cheat) => cheat.name === getCheat() && cheat.game === getGame()).map((filteredCheat) => { return <span className={"status-" + removeSpaces(filteredCheat.status)}>{filteredCheat.status.toUpperCase()}</span> })}</span>
                </div>
                <div className="info-title">
                  {data.filter((cheat) => cheat.name === getCheat() && cheat.game === getGame()).map((filteredCheat) => { return filteredCheat.name.toUpperCase() })}
                </div>
              </div>
              <div className="info-prices">
                {data.filter((cheat) => cheat.name === getCheat() && cheat.game === getGame()).map((filteredCheat) => (
                  <>
                    {filteredCheat.day ? (
                      <div className="price">
                        DAY: <span className="cost">${filteredCheat.day}</span>
                      </div>
                    ) : null}

                    {filteredCheat.days ? (
                      <div className="price">
                        3 DAYS: <span className="cost">${filteredCheat.days}</span>
                      </div>
                    ) : null}
                    {filteredCheat.week ? (
                      <div className="price">
                        WEEK: <span className="cost">${filteredCheat.week}</span>
                      </div>
                    ) : null}
                    {filteredCheat.weeks ? (
                      <div className="price">
                        2 WEEKS: <span className="cost">${filteredCheat.week}</span>
                      </div>
                    ) : null}
                    {filteredCheat.month ? (
                      <div className="price">
                        MONTH: <span className="cost">${filteredCheat.month}</span>
                      </div>
                    ) : null}
                    {filteredCheat.months ? (
                      <div className="price">
                        3 MONTHS: <span className="cost">${filteredCheat.months}</span>
                      </div>
                    ) : null}
                    {filteredCheat.invite ? (
                      <div className="price">
                        INVITE: <span className="cost">${filteredCheat.invite}</span>
                      </div>
                    ) : null}
                    {filteredCheat.life ? (
                      <div className="price">
                        LIFE: <span className="cost">${filteredCheat.life}</span>
                      </div>
                    ) : null}
                    {filteredCheat.fullAccess ? (
                      <div className="price">
                        FULL ACCESS: <span className="cost">${filteredCheat.fullAccess}</span>
                      </div>
                    ) : null}
                    {filteredCheat.partialAccess ? (
                      <div className="price">
                        NFA: <span className="cost">${filteredCheat.partialAccess}</span>
                      </div>
                    ) : null}
                    {filteredCheat.special ? (
                      <div className="price special">
                        COST: <span className="cost">${filteredCheat.special}</span>
                      </div>
                    ) : null}
                  </>
                ))}
              </div>

              {data.filter((cheat) => cheat.name === getCheat() && cheat.game === getGame()).map((filteredCheat) => (
                <>
                  <div className='payment-choices'>Choose preferred payment method</div>
                  <a className="purchase-link" target="_blank" rel='noreferrer' href={`${filteredCheat.stripe}`}>
                    <div className="">
                    </div>
                  </a>
                  <PopupButton url={`https://fivestarsolutions.bgng.io/embed/${filteredCheat.seopath}`} />
                </>
 
              ))}
            </div>

          </div>
          <div className="product-features">
            <div className='feature-nav'>
              <span onClick={() => toggleSelect(0)} className={activeSelect === 0 ? "active-nav" : ""}>FEATURES</span>
              <span onClick={() => toggleSelect(1)} className={activeSelect === 1 ? "active-nav" : ""}>REQUIREMENTS</span>
            </div>
            <div className={activeSelect === 0 ? "feature-box feature-box-active features" : "feature-box features"}>
              {data.filter((cheat) => cheat.name === getCheat() && cheat.game === getGame()).map((filteredCheat) =>
                filteredCheat.features.map((feature) => {
                  return (
                    <div className='feature-list'>
                      <li className='feature-list-title'>{feature.title}</li>
                      {feature.items.map((item) => (
                        <li >{item}</li>
                      ))}
                    </div>
                  )
                })
              )}
            </div>
            <div className={activeSelect === 1 ? "feature-box feature-box-active requirements" : "feature-box requirements"}>
              {data.filter((cheat) => cheat.name === getCheat() && cheat.game === getGame()).map((filteredCheat) =>
                filteredCheat.requirments.map((requirement) => {
                  return (
                    <>
                      {requirement.card ? (
                        <div className='requirement-item'>
                          <span className='requirement-title'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#fff4ff" d="M10 13a1 1 0 1 0 1 1a1 1 0 0 0-1-1Zm0-4a1 1 0 1 0 1 1a1 1 0 0 0-1-1Zm4 0a1 1 0 1 0 1 1a1 1 0 0 0-1-1Zm7 4a1 1 0 0 0 0-2h-2V9h2a1 1 0 0 0 0-2h-2.18A3 3 0 0 0 17 5.18V3a1 1 0 0 0-2 0v2h-2V3a1 1 0 0 0-2 0v2H9V3a1 1 0 0 0-2 0v2.18A3 3 0 0 0 5.18 7H3a1 1 0 0 0 0 2h2v2H3a1 1 0 0 0 0 2h2v2H3a1 1 0 0 0 0 2h2.18A3 3 0 0 0 7 18.82V21a1 1 0 0 0 2 0v-2h2v2a1 1 0 0 0 2 0v-2h2v2a1 1 0 0 0 2 0v-2.18A3 3 0 0 0 18.82 17H21a1 1 0 0 0 0-2h-2v-2Zm-4 3a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1Zm-3-3a1 1 0 1 0 1 1a1 1 0 0 0-1-1Z" /></svg>GRAPHICS CARD:</span>
                          <span className='requirement-text'>{requirement.card}</span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {requirement.winver ? (
                        <div className='requirement-item'>
                          <span className='requirement-title'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 48"><path fill="#fff4ff" stroke="currentColor" stroke-linejoin="round" stroke-width="3.833" d="m6.75 11.063l12.938-1.725v12.075H6.75v-10.35Zm18.112-2.218L41.25 6.75v14.663H24.862V8.845Zm0 18.605l16.388.383V41.25l-16.388-2.683V27.45ZM6.75 26.588l12.938.311V37.8L6.75 35.62v-9.032Z" /></svg>WINDOWS VERSION:</span>
                          <span className='requirement-text'>{requirement.winver}</span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {requirement.other ? (
                        <div className='requirement-item'>
                          <span className='requirement-title'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#fff4ff" d="M10.125 22q-.375 0-.65-.25t-.325-.625l-.3-2.325q-.325-.125-.612-.3t-.563-.375l-2.175.9q-.35.15-.7.038t-.55-.438L2.4 15.4q-.2-.325-.125-.7t.375-.6l1.875-1.425Q4.5 12.5 4.5 12.338v-.675q0-.163.025-.338L2.65 9.9q-.3-.225-.375-.6t.125-.7l1.85-3.225q.2-.325.55-.437t.7.037l2.175.9q.275-.2.575-.375t.6-.3l.3-2.325q.05-.375.325-.625t.65-.25h3.75q.375 0 .65.25t.325.625l.3 2.325q.325.125.613.3t.562.375l2.175-.9q.35-.15.7-.038t.55.438L21.6 8.6q.2.325.125.7t-.375.6l-1.875 1.425q.025.175.025.338v.674q0 .163-.05.338l1.875 1.425q.3.225.375.6t-.125.7l-1.85 3.2q-.2.325-.562.45t-.713-.025l-2.125-.9q-.275.2-.575.375t-.6.3l-.3 2.325q-.05.375-.325.625t-.65.25h-3.75Zm1.925-6.5q1.45 0 2.475-1.025T15.55 12q0-1.45-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12q0 1.45 1.013 2.475T12.05 15.5Z" /></svg>OTHER:</span>
                          <span className='requirement-text'>{requirement.other}</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                })
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="error-title">404</div>
          <div className="error-sub">This link has either been removed or does not exist.</div>
        </>
      )}
    </div>
  );



}
