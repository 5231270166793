//RUST IMAGES

import rustlastcheat from '../images/rust/rustlastcheat.webp';
import rustdisarray from '../images/rust/rustdisarrayfivestar.webp';
import rustdivision from '../images/rust/rustdivisionfivestar.webp';
import rustdisconnect from '../images/rust/rustdisconnectfivestar.webp';
import rustfluent from '../images/rust/rustfluentfivestar.webp';
import rustquantumpublic from '../images/rust/rustquantumpublicfivestar.webp';
import rustquantumprivate from '../images/rust/rustquantumprivatefivestar.webp';
import rustrustsense from '../images/rust/rustsensefivestar.webp';
import rustaccounts from '../images/rust/fivestarrustaccounts.webp';
import RustMonolith from '../images/rust/Monolith.webp';
import RustRecoilClub from '../images/rust/RC picture.png';

//TARKOV IMAGES
import tarkovcoffeelite from '../images/tarkov/tarkovcoffeelitefivestar.webp';
import tarkovcoffeefull from '../images/tarkov/tarkovcoffeefullfivestar.webp';
import tarkovterralabs from '../images/tarkov/tarkovterrafivestar.webp';
import tarkovquantumprivate from '../images/tarkov/tarkovquantumprivatefivestar.webp';

//RAINBOW SIX SIEGE IMAGES
import rainbowklar from '../images/rainbow/rainbowklarfivestar.webp';
import rainbowarmy from '../images/rainbow/rainbowcheatarmyfivestar.webp';

//DAYZ IMAGES
import dayzintel from '../images/dayz/dayzintellesensefivestar.webp';
import KlarDayz from '../images/dayz/Dayzklarfivestar.webp';

//SPOOFER IMAGES
import spooferexception from '../images/spoofer/spooferexception.webp';
import spooferperm from '../images/spoofer/spooferperm.webp';

import permimg from '../images/spoofer/permspooferimg.webp';

//CSGO IMAGES
import csgofecurity from '../images/csgo/csgofecurityfivestar.webp';

//APEX IMAGES
import apexfecurity from '../images/apex/apexfecuirtyfivestar.webp';
import apexcola from '../images/apex/fivestarapexcola.webp';


//BATTLEBIT IMAGES
import battlefecurity from '../images/battlebit/battlebitfecuirtyfivestar.webp';

//MW IMAGES
import mwfecurity from '../images/mw/mwfecuirtyfivestar.webp';
import mwfecurityunlocker from '../images/mw/mwfecuirtyunlockerfivestar.webp';
import MWStealthfull from '../images/mw/Call of duty stealth.png';

//VALORANT IMAGES
import valcoffee from '../images/valorant/valorantcoffeefivestar.webp';
import valfecurity from '../images/valorant/valorantcfecuirtyfivestars.webp'; 
import valxorpro from '../images/valorant/fivestarvalorantxorpro.webp';
import valxoralaska from '../images/valorant/fivestarvalorantxoralaska.webp'; 




export const data = [

    //RUST
    {
        name: "Quantum Public",
        game: "Rust",
        image: rustquantumpublic,
        seopath: 'Quantum-',
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Quantum-Public",
         media: [
            {"url": "https://www.youtube.com/embed/_rwD0a-CC-w?si=_P_3aAmrgYLszgPf"},
        ],
        day: "14",
        week: "60",
        month: "120",
        life: "600",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "HWID Spoofer Included",
                    "Customizable",
                    "Low CPU Usage",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "AIMBOT",
                "items": [
                    "Aimbot",
                    "PSilent",
                    "PSilent Heli",
                    "Smoothing",
                    "Recoil Comp",
                    "Prediction",
                    "Hit Chance",
                    "Auto Shoot Enemy",
                    "Autofire",
                    "Silent No Spread",
                    "Bone Override"
                  ]
            },
            {
                "title": "AIM VISUALS",
                "items": [
                    "Simulate Projectiles",
                    "Aim Marker",
                    "Crosshair",
                    "Fov Circle",
                    "Target Snapline"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Enemy Tracker",
                    "Players",
                    "Scientists",
                    "Wounded",
                    "Sleeper",
                    "Friends",
                    "Radar",
                    "Rainbow Hands",
                    "FOV Arrows",
                    "Looking at me Alert",
                    "Chams (Rainbow/Single Color)",
                    "Skeleton",
                    "Username",
                    "Box ESP",
                    "Held Item",
                    "Health Bar",
                    "Hotbar",
                    "Clothing",
                    "View Direction",
                    "Raid ESP",
                    "All Resources",
                    "All Lootables",
                    "All World Vehicles",
                    "All Deployables (Including Stash/Trap ESP)"
                  ]
            },
            {
                "title": "UTILITY",
                "items": [
                    "Debug Camera",
                    "3rd Person",
                    "Flyhack",
                    "Interactive Noclip",
                    "Teleport",
                    "Silent Walk",
                    "Omnisprint",
                    "Spinbot",
                    "Spiderman",
                    "Smaller Hitbox",
                    "Infinte Jump",
                    "Increase Height",
                    "Instant Loot",
                    "Instant Revive",
                  ]
            },
            {
                "title": "COMBAT",
                "items": [
                    "Recoil",
                    "Full Auto",
                    "Fast Fire",
                    "No Sway",
                    "No Spread",
                    "Increase Velocity",
                    "Can Shoot",
                    "Spoof Hit distance",
                    "Thick Bullet",
                    "Instant Eoka",
                    "Hammer Spam",
                    "No Aimcone",
                    "Fast Bow",
                    "Instant Charge",
                  ]
            },
            {
                "title": "GAME VISUALS",
                "items": [
                    "Day Hack",
                    "Sky Changer",
                    "Ambient",
                    "Bright Stars",
                    "FOV Changer",
                    "Zoom",
                    "Draw Colliders",
                    "Hardcore Map",
                    "Hardcore Compass",
                    "Disable Grass/Weather Effects"
                  ]
            },
            {
                "title": "CONFIG",
                "items": [
                    "Save/Load Legit Config",
                    "Save/Load Rage Config",
                    "Menu Theme Color",
                    "Hotkey to Switch Configs"
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/ 11"},
            {"other": "SPOOFER INCLUDED"},
        ],
    },
    // {
    //     name: "Quantum External",
    //     game: "Rust",
    //     image: rustquantumpublic,
    //     seopath: 'quantum-external',
    //     status: "Detected",
    //     stripe: "https://fivestar.sellhub.cx/product/Quantum-External",
    //      media: [
    //         {"url": "https://www.youtube.com/embed/_rwD0a-CC-w?si=_P_3aAmrgYLszgPf"},
    //     ],
    //     day: "8",
    //     week: "33",
    //     month: "65",
    //     features: [
    //         {
    //             "title": "INFO",
    //             "items": [
    //                 "Undetected",
    //                 "Customizable",
    //                 "Low CPU Usage",
    //                 "Cheap & Simple Usage",
    //                 "Unique Bypass"
    //               ]
    //         },
    //         {
    //             "title": "AIMBOT",
    //             "items": [
    //                 "Aimbot",
    //                 "PSilent",
    //                 "PSilent Heli",
    //                 "Smoothing",
    //                 "Recoil Comp",
    //                 "Hit Chance",
    //                 "Autofire",
    //                 "Silent No Spread",
    //                 "Bone Override"
    //               ]
    //         },
    //         {
    //             "title": "World VISUALS",
    //             "items": [
    //                 "All Resources",
    //                 "Raid ESP",
    //                 "All Lootables",
    //                 "All World Vehicles",
    //                 "All Deployables",
    //                 "Animals",
    //                 "Crates",
    //                 "food",
    //                 "traps",
    //               ]
    //         },
    //         {
    //             "title": "VISUALS",
    //             "items": [
    //                 "Enemy Tracker",
    //                 "Players",
    //                 "Scientists",
    //                 "Wounded",
    //                 "Sleeper",
    //                 "Friends",
    //                 "Radar",
    //                 "Rainbow Hands",
    //                 "FOV Arrows",
    //                 "Looking at me Alert",
    //                 "Chams (Rainbow/Single Color)",
    //                 "Skeleton",
    //                 "Username",
    //                 "Box ESP",
    //                 "Held Item",
    //                 "Health Bar",
    //                 "Hotbar",
    //                 "Clothing",
    //                 "View Direction",
    //               ]
    //         },
    //         {
    //             "title": "UTILITY",
    //             "items": [
    //                 "Debug Camera",
    //                 "Flyhack",
    //                 "Interactive Noclip",
    //                 "Silent Walk",
    //                 "Omnisprint",
    //                 "Spinbot",
    //                 "Spiderman",
    //                 "Smaller Hitbox",
    //                 "Infinte Jump",
    //                 "Instant Loot",
    //                 "Instant Revive",
    //                 "NoSpike/Fire",
    //                 "NoFall",
    //                 "Noslowdown",
    //                 "No Aimblock",
    //                 "No gesturerestrictions",
    //               ]
    //         },
    //         {
    //             "title": "COMBAT",
    //             "items": [
    //                 "Recoil",
    //                 "Full Auto",
    //                 "Fast Fire",
    //                 "No Sway",
    //                 "No Spread",
    //                 "Increase Velocity",
    //                 "Can Shoot",
    //                 "Spoof Hit distance",
    //                 "Thick Bullet",
    //                 "Instant Eoka",
    //                 "Hammer Spam",
    //                 "No Aimcone",
    //                 "Fast Bow",
    //                 "Instant Charge",
    //               ]
    //         },
    //         {
    //             "title": "GAME VISUALS",
    //             "items": [
    //                 "Day Hack",
    //                 "Sky Changer",
    //                 "Ambient",
    //                 "Bright Stars",
    //                 "FOV Changer",
    //                 "Zoom",
    //                 "Draw Colliders",
    //                 "Hardcore Map",
    //                 "Hardcore Compass",
    //                 "Disable Grass/Weather Effects"
    //               ]
    //         },
    //         {
    //             "title": "CONFIG",
    //             "items": [
    //                 "Save/Load Legit Config",
    //                 "Save/Load Rage Config",
    //               ]
    //         },
    //     ],
    //     requirments: [
    //         {"card": "NVIDIA & AMD"},
    //         {"winver": "WINDOWS 10"},
    //         {"other": "USB Required"},
    //     ],
    // },
    {
        name: "Monolith External",
        game: "Rust",
        image: RustMonolith,
        seopath: 'monolith-external',
        status: "Undetected",
        stripe: "https://fivestar.sellhub.cx/product/Monolith-External",
         media: [
            {"img": RustMonolith},
        ],
        week: "44",
        month: "85",
        life: "780",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "Customizable",
                    "Low CPU Usage",
                    "External",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "AIMBOT",
                "items": [
                    "Silent Aimbot",
                    "Memory Aimbot",
                    "Smoothing",
                    "Recoil Comp",
                    "Prediction",
                    "Hit Chance",
                    "Auto Shoot Enemy",
                    "Autofire",
                    "Silent No Spread",
                    "Fastest Possible bullets"
                  ]
            },
            {
                "title": "AIM VISUALS",
                "items": [
                    "Simulate Projectiles",
                    "Aim Marker",
                    "Crosshair",
                    "Fov Circle",
                    "Target Snapline"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Enemy Tracker",
                    "Players",
                    "Scientists",
                    "Wounded",
                    "Sleeper",
                    "Friends",
                    "Radar",
                    "Hands chams",
                    "FOV Arrows",
                    "Looking at me Alert",
                    "Chams",
                    "Skeleton",
                    "Username",
                    "Box ESP",
                    "Held Item",
                    "Health Bar",
                    "Hotbar",
                    "Clothing",
                    "View Direction",
                    "Raid ESP",
                    "All Resources",
                    "All Lootables",
                    "All World Vehicles",
                    "All Deployables (Including Stash/Trap ESP)",
                    "Crosshair Ammo Indicator",
                    "projectile Trails",
                    "Player Healthbars",
                    "Adjustable Filled box amount",
                    "Draw Hit Notification",
                    "Draw Projectiles"
                  ]
            },
            {
                "title": "UTILITY",
                "items": [
                    "Debug Camera",
                    "3rd Person",
                    "Flyhack",
                    "Interactive Noclip",
                    "Teleport",
                    "Silent Walk",
                    "Omnisprint",
                    "Anti-Flyhack Violations",
                    "Anti-Speed Violations",
                    "Spinbot",
                    "Spiderman",
                    "Smaller Hitbox",
                    "Infinte Jump",
                    "Walk on Water",
                    "Turn Placeables",
                    "Increase Height",
                    "No Fall",
                    "No Slowdown",
                    "No Blackout",
                    "No Collision",
                    "Sign Painter",
                    "Instant Loot",
                    "Instant Revive",
                    "Instant Med",
                    "Instant Med Friend"
                  ]
            },
            {
                "title": "COMBAT",
                "items": [
                    "Recoil",
                    "Full Auto",
                    "Fast Fire",
                    "No Sway",
                    "No Spread",
                    "Increase Velocity",
                    "Can Shoot",
                    "Spoof Hit distance",
                    "Thick Bullet",
                    "Instant Eoka",
                    "Aim in Mountable",
                    "No Melee Slowdown",
                    "No Melee Punch",
                    "2x Melee Distance",
                    "No Aimcone",
                    "Fast Bow",
                    "Instant Charge",
                  ]
            },
            {
                "title": "GAME VISUALS",
                "items": [
                    "Day Hack",
                    "Sky Changer",
                    "Ambient",
                    "Bright Stars",
                    "FOV Changer",
                    "Zoom",
                    "Draw Colliders",
                    "Hardcore Map",
                    "Hardcore Compass",
                    "Disable Grass/Weather Effects"
                  ]
            },
            {
                "title": "CONFIG",
                "items": [
                    "Save Config",
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA ONLY"},
            {"winver": "WINDOWS 10/11 (Some windows 11 users have issues might have to downgrade)"},
        ],
    },
    // {
    //     name: "EvilCheats Slotted",
    //     game: "Rust",
    //     image: rustevilcheats,
        
    //     status: "Undetected",
    //     stripe: "https://fivestar.cheating.store/product/Evil-Cheats-slotted-private",
    //     media: [
    //         { img: rustevilcheats },

    //       ],
    //     week: "38",
    //     month: "95",
    //     seopath: 'EvilCheats Slotted" data-sellpass-domain="putdovhsunybbxispxqo1rfgzdh8ul8m.sellpass.io"',
    //     features: [
    //         {
    //             "title": "SPECS",
    //             "items": [
    //                 "Internal",
    //                 "Updates Frequently",
    //                 "Fully Customizable",
    //                 "No Performance Impact",
    //                 "AMD & Intel Support",
    //                 "Cloud Config",
    //                 "High Anonymity Spoofer",
    //                 "Keybind System"
    //               ]
    //         },
    //         {
    //             "title": "AIMBOT",
    //             "items": [
    //                 '[Main] | PSilent',
    //                 '[Main] | Enabled',
    //                 '[Main] | Aimbone',
    //                 '[Main] | Hitbox',
    //                 '[Main] | Wounded',
    //                 '[Main] | Visible',
    //                 '[Main] | Scientists',
    //                 '[Main] | Pierce',
    //                 '[Main] | In FOV',
    //                 '[Main] | Fast Bullet',
    //                 '[Main] | Vehicle Aimbot',
    //                 '[Main] | Visual',
    //                 '[Main] | Smart Aim (Private)',
    //                 '[Main] | Targets (Private)',
    //                 '[Main] | Crosshair',
    //                 '[Main] | FOV',
    //                 '[Main] | Colors',
    //                 '[Main] | Target Color',
    //                 '[Main] | View Offset'
    //               ]
    //         },
    //         {
    //             "title": "ESP",
    //             "items": [
    //                 '[Player] | General',
    //                 '[Player] | Enable',
    //                 '[Player] | Name',
    //                 '[Player] | Distance',
    //                 '[Player] | Outline Font',
    //                 '[Player] | Skeleton',
    //                 '[Player] | Healthbar',
    //                 '[Player] | Chams (Private)',
    //                 '[Player] | Item',
    //                 '[Player] | Team ID',
    //                 '[Player] | Wounded',
    //                 '[Player] | Sleepers',
    //                 '[Player] | Autoscale',
    //                 '[Player] | Max Distance',
    //                 '[Player] | Font Scale',
    //                 '[Player] | Colors',
    //                 '[Player] | Name Color',
    //                 '[Player] | Distance Color',
    //                 '[Player] | Team Color',
    //                 '[Player] | Item Color',
    //                 '[Player] | Team ID Color',
    //                 '[Player] | Skeleton',
    //                 '[Player] | Skeleton Color',
    //                 '[Player] | Skeleton Color',
    //                 '[Player] | Box',
    //                 '[Player] | Box Color',
    //                 '[Player] | Box Color',
    //                 '[Animal] | ESP',
    //                 '[Animal] | Update IncomiXng',
    //                 '[A.I.] | General',
    //                 '[A.I.] | Name',
    //                 '[A.I.] | Distance',
    //                 '[A.I.] | Font Scale',
    //                 '[A.I.] | Scientists',
    //                 '[A.I.] | Type',
    //                 '[A.I.] | Scientist',
    //                 '[A.I.] | Tunnel Dweller',
    //                 '[A.I.] | Underwater Dweller',
    //                 '[A.I.] | Item',
    //                 '[A.I.] | Maximum Distance',
    //                 '[A.I.] | Vehicles',
    //                 '[A.I.] | Vehicle Type',
    //                 '[A.I.] | Helicopter',
    //                 '[A.I.] | Vehicle Health',
    //                 '[A.I.] | Bradley',
    //                 '[A.I.] | Vehicle Distance',
    //                 '[A.I.] | Colors',
    //                 '[A.I.] | Name Color',
    //                 '[A.I.] | Visible Color',
    //                 '[A.I.] | Invisible Color',
    //                 '[A.I.] | Distance Color',
    //                 '[A.I.] | Item Color',
    //                 '[World] | General',
    //                 '[World] | Item Selection',
    //                 '[World] | Name',
    //                 '[World] | Distance',
    //                 '[World] | Maximum Distance',
    //                 '[World] | Font Scale',
    //                 '[World] | Colors',
    //                 '[World] | Name Color',
    //                 '[World] | Distance Color'
    //               ]
    //         },
    //         {
    //             "title": "MISC",
    //             "items": [
    //                 '[Player] | General',
    //                 '[Player] | Disable Hotkeys',
    //                 '[Player] | Silent Farm',
    //                 '[Player] | Belt Info',
    //                 '[Player] | Interactive Debugcamera',
    //                 '[Player] | Spinbot',
    //                 '[Player] | Fake Gunshots',
    //                 '[Player] | Field of View',
    //                 '[Player] | Gunshot Rate',
    //                 '[Player] | Always Hotspot',
    //                 '[Player] | Name Spoof',
    //                 '[Player] | Custom Name (Private)',
    //                 '[Player] | Restrictions',
    //                 '[Player] | Block Server Commands',
    //                 '[Player] | Allow Attack',
    //                 '[Player] | Allow Omni-Sprinting',
    //                 '[Player] | Superjump',
    //                 '[Player] | Local Admin',
    //                 '[Player] | Hold Gun in Vehicle',
    //                 '[Player] | Instant Jump',
    //                 '[Player] | Water Walk',
    //                 '[Player] | No Fall',
    //                 '[Player] | Disable Player Collision',
    //                 '[Player] | Disable Tree Collision',
    //                 '[World] | Weather',
    //                 '[World] | No Fog',
    //                 '[World] | No Wind',
    //                 '[World] | No Rain',
    //                 '[World] | No Clouds',
    //                 '[World] | Always Day',
    //                 '[World] | Cavelight',
    //                 '[World] | Nightlight',
    //                 '[World] | Underwater',
    //                 '[World] | Better Sight',
    //                 '[World] | Time',
    //                 '[World] | Timescale',
    //                 '[World] | Raid ESP'
    //               ]
    //         },
    //         {
    //             "title": "WEAPON",
    //             "items": [
    //                 '[Gun] | General',
    //                 '[Gun] | No Recoil',
    //                 '[Gun] | No Spread',
    //                 '[Gun] | No Sway',
    //                 '[Gun] | Recoil Intensity',
    //                 '[Gun] | Spread Intensity',
    //                 '[Gun] | Force Auto',
    //                 '[Gun] | Eoka',
    //                 '[Gun] | Instant Shoot'
    //               ]
    //         }
    //     ],
    //     requirments: [
    //         {"card": "NVIDIA & AMD"},
    //         {"winver": "WINDOWS 10/11"},
    //         {"other": "Run our Client's Compatibility Check to ensure your PC Supports our Products."},
    //     ],
    // },

    // {
    //     name: "Division",
    //     game: "Rust",
    //     image: rustdivision,
    //     seopath: 'Division-Rust',
    //     status: "Undetected",
    //     stripe: "https://fivestar.cheating.store/product/Division",
    //     media: [
    //         {"img": rustdivision},
    //     ],
    //     day: "9",
    //     week: "35",
    //     month: "65",
    //     features: [
    //         {
    //             "title": "AIMBOT",
    //             "items": [
    //                 "Enabled [keybind]",
    //                 "FOV",
    //                 "Smoothness",
    //                 "Hit Chance",
    //                 "Bone [Head / Chest / Closest / Stomach]",
    //                 "Silent",
    //                 "Memory",
    //                 "Remove FOV",
    //                 "Target Team",
    //                 "Target NPC",
    //                 "Lock Target",
    //                 "Remove Aim Line",
    //                 "HITBOX OVERRIDE [Head / Body / Random]"
    //               ]
    //         },
    //         {
    //             "title": "ESP",
    //             "items": [
    //                 "Player",
    //                 "Box [Normal / Corner]",
    //                 "Chams [Pink / Black / White / Red / Red2]",
    //                 "Skeleton",
    //                 "Name",
    //                 "Distance",
    //                 "Weapon",
    //                 "Team",
    //                 "Team ID",
    //                 "Flags [aim, mount, crawl, wound, team, division, safezone]",
    //                 "Health",
    //                 "Sleepers",
    //                 "Scientists",
    //                 "Hotbar / Clothing",
    //                 "Out of FOV Arrows",
    //                 "Look Direction",
    //                 "Looking At You",
    //                 "Animals",
    //                 "Resources",
    //                 "Lootables",
    //                 "Vehicles",
    //                 "Traps",
    //                 "Foods",
    //                 "World",
    //                 "TC Auth List",
    //                 "Auto Turret Auth List",
    //                 "Dropped Items"
    //               ]
    //         },
    //         {
    //             "title": "RADAR",
    //             "items": [
    //                 "Enabled",
    //                 "Players",
    //                 "Entities",
    //                 "Size",
    //                 "Distance"
    //               ]
    //         },
    //         {
    //             "title": "WEB RADAR",
    //             "items": [
    //                 "Web Link for online radar for friends",
    //               ]
    //         },
    //         {
    //             "title": "MISC",
    //             "items": [
    //                 "Weapon",
    //                 "No Recoil [slider]",
    //                 "No Spread [slider] + shotguns",
    //                 "No Sway",
    //                 "Force Automatic",
    //                 "Instant Eoka",
    //                 "Instant Bow",
    //                 "Instant Compound Bow",
    //                 "Extended Melee Range",
    //                 "No Block Sprint",
    //                 "Melee Hit Assist",
    //                 "Player",
    //                 "Admin Flags",
    //                 "Block Server Commands",
    //                 "Crosshair [Dot / Normal +]",
    //                 "FOV Changer",
    //                 "Bright Night",
    //                 "Bright Caves",
    //                 "Starry Nights",
    //                 "Always Day",
    //                 "Instant Revive",
    //                 "Instant Untie",
    //                 "Unlock Mountable Angles",
    //                 "Long Neck",
    //                 "Movement",
    //                 "Spiderman",
    //                 "Infinite Jump",
    //                 "No Fall",
    //                 "No Collision",
    //                 "Suicide",
    //                 "Fake Lag",
    //                 "If player looking at you, auto fakelag",
    //                 "Fly Hack [Noclip]",
    //                 "Interactive DebugCamera"
    //               ]
    //         },
    //         {
    //             "title": "CONFIG",
    //             "items": [
    //                 "Configs",
    //                 "Custom Config System",
    //                 "Players",
    //                 "Player List",
    //                 "Settings",
    //                 "Draw DEBUG ESP",
    //                 "Cheat Indicators",
    //                 "Menu Bind",
    //                 "Combat Mode"
    //               ]
    //         },
    //     ],
    //     requirments: [
    //         {"card": "NVIDIA & AMD"},
    //         {"winver": "WINDOWS 10/11"},
    //         {"other": "DIRECTX 11"},
    //     ],
    // },
    {
        name: "Fluent",
        game: "Rust",
        image: rustfluent,
        seopath: "Fluentgg",
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Fluent",
         media: [
            {"img": rustfluent},
        ],
        day: "12",
        week: "50",
        month: "120",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Draw FOV Circle",
                    "Smoothness",
                    "Aim Spot",
                    "Auto Shoot",
                    "Rapid Fire",
                    "Bullet TP",
                    "Patrol Heli Bullet TP",
                    "Instant Hit",
                    "Auto Shoot Wait For Instant Hit"
                  ]
            },
            {
                "title": "ESP",
                "items": [
                    "Players: Name, Box, Health Bar, Distance, Skeleton",
                    "Active Item: Icons, Game Names, and Shortened Names for quick identification.",
                    "Enemy Color Override: Customize the visual representation of enemy players.",
                    "Hotbar: Easily view the items in your character's hotbar.",
                    "Sleepers: Identify sleeping players for strategic purposes.",
                    "Radar: Enable radar tracking of players, sleepers, and wounded characters.",
                    "NPCs: Name, Box, Health Bar, Distance, Skeleton",
                    "Animals: Name, Icons, Health Bar, Distance",
                    "Vehicles: Name, Icons, Health Bar, Distance",
                    "Ores: Name, Distance",
                    "Collectibles: Name, Distance",
                    "Stash: Name, Distance",
                    "Crates: Name, Distance",
                    "AI: Name, Health Bar, Distance (Cargo, Patrol Heli, Bradley)",
                    "Deployables: Name, Distance (Tool Cupboards, All Workbenches, Sleeping bags)",
                    "Items: Name, Distance (Melee Weapons, Tier 1/2/3 Weapons, Explosives, Resources, Tools, Medical, Ammo,",
                    "Traps: Name, Distance (Shotgun Trap, Flame Turret, SAM Site, Auto Turrets, Bear Traps, Landmines)",
                    "Show Authorized Traps: Display authorized traps for better base defense.",
                    "Raids: Name, Distance (All Raid Items, Seconds of Last Raid Item)"
                  ]
            },
            {
                "title": "OTHER",
                "items": [
                    "View Model Hand Chams: Customize weapon and hand appearance for various modes.",
                    "View Model Weapon Chams: Customize weapon appearance for various modes.",
                    "No Recoil, No Spread, No Sway",
                    "Faster Bullets",
                    "Instant Eoka",
                    "Fast Bow",
                    "Instant Compound Charge",
                    "Auto Reload, Faster Auto Reload: Automate and expedite the reload process.",
                    "Better Penetration: Increase bullet penetration through surfaces.",
                    "Shoot Through Wounded: Enable bullets to pass through wounded players.",
                    "Shoot Through Teammates: Allow bullets to pass through friendly players without causing harm.",
                    "Hit Override: Override hit detection for headshots and chest shots.",
                    "Silent Melee: Execute melee attacks silently for stealth gameplay.",
                    "Weapon Spam: Rapidly fire weapons for enhanced combat efficiency."
                  ]
            },
            {
                "title": "MOVEMENT",
                "items": [
                    "Spider-man: Gain the ability to climb walls and surfaces.",
                    "No Fall",
                    "Infinite Jump",
                    "Jesus Mode: Walk on water surfaces without sinking.",
                    "Omni-sprint: Run sideways to confuse opponents during combat.",
                    "Anti Fly-hack Kick",
                    "Noclip Collisions, No Tree Collisions, No Player Collisions: Overcome collision limitations for smoother movement.",
                    "Interactive Debug with Shoot Indicators: Receive visual cues for optimal shooting opportunities."
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Admin Flag: Enable admin privileges for special access.",
                    "Block Server Commands: Prevent unauthorized server command usage.",
                    "Auto Upgrade: Automatically upgrade structures when materials are available.",
                    "Disable After (X) Seconds: Set a timer to disable certain features after a defined duration.",
                    "Desync: Adjust desynchronization settings for better gameplay performance.",
                    "On Key Max Desync Override",
                    "Instant Loot",
                    "Loot Stashes Without Unhiding",
                    "No Screen Shake",
                    "Always Hit Hot Spot",
                    "Silent Farm:",
                    "Auto Farm Barrel"
                  ]
            },
            {
                "title": "CONFIG",
                "items": [
                    "Create Your Own Config/Use Someone Else's Config: Save personalized configurations for future use.",
                    "Save and Load Config: Store and retrieve customized settings easily."
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10+11"},
            {"other": "HWID Spoofer included only for windows 10"},
        ],
    },
    {
        name: "Quantum Private",
        game: "Rust", 
        image: rustquantumprivate, 
        alt:'x',
        seopath: 'Quantum-Private',
    

        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Quantum-private-Rust",
         media: [
            {"url": "https://www.youtube.com/embed/_rwD0a-CC-w?si=_P_3aAmrgYLszgPf"},
        ],
        month: "150",
        invite: "400",
        life: "1200",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "HWID Spoofer Included",
                    "Customizable",
                    "Low CPU Usage",
                    "Internal",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "AIMBOT",
                "items": [
                    "Memory Aimbot",
                    "PSilent",
                    "PSilent Heli",
                    "Smoothing",
                    "Recoil Comp",
                    "Prediction",
                    "Hit Chance",
                    "Auto Shoot Enemy",
                    "Autofire",
                    "Magic Bullet (Manipulation)",
                    "Magic Bullet Heli",
                    "Simulate Angles",
                    "Instant Kill",
                    "Silent No Spread",
                    "Bone Override",
                    "Shoot through Walls (Ladder Magic)",
                    "Reflect Bullets"
                  ]
            },
            {
                "title": "AIM VISUALS",
                "items": [
                    "Simulate Projectiles",
                    "Aim Marker",
                    "Crosshair (NAZI)",
                    "Fov Circle",
                    "Target Snapline",
                    "Bullet Tracers"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Enemy Tracker",
                    "Players",
                    "Scientists",
                    "Wounded",
                    "Sleeper",
                    "Friends",
                    "Radar",
                    "Rainbow Hands",
                    "FOV Arrows",
                    "Looking at me Alert",
                    "Chams (Rainbow/Single Color)",
                    "Skeleton",
                    "Username",
                    "Box ESP",
                    "Held Item",
                    "Health Bar",
                    "Hotbar",
                    "Clothing",
                    "View Direction",
                    "Raid ESP",
                    "All Resources",
                    "All Lootables",
                    "All World Vehicles",
                    "All Deployables (Including Stash/Trap ESP)"
                  ]
            },
            {
                "title": "AUTOMATION",
                "items": [
                    "Silent Melee Ores",
                    "Silent Melee Tree",
                    "Silent Melee Players",
                    "Silent Melee Animals",
                    "Auto Upgrade",
                    "Auto Clone",
                    "Auto Suicide",
                    "Auto Knock",
                    "Auto Open",
                    "Auto Free Crates",
                    "Auto Stack Wood",
                    "Auto Stack Stone",
                    "Auto Stack Metal",
                    "Auth Auth Codes",
                    "Auth Lock Codes",
                    "Auto Collect",
                    "Auto Loot Corpse",
                    "Auto Disarm Landmines",
                    "Disable Recycler",
                    "Empty Inventory",
                    "Auto Loot (Select Weapons, Resources ETC.)",
                    "Auto Run",
                    "Auto Refill Jack Hammer",
                    "Auto Walk to Marker",
                    "Auto Farmbot Ore (Runs to nearest selected node and farms, Repeat..)"
                  ]
            },
            {
                "title": "UTILITY",
                "items": [
                    "Debug Camera",
                    "3RD Person",
                    "Flyhack",
                    "Interactive Noclip",
                    "Teleport",
                    "Silent Walk",
                    "Omnisprint",
                    "Anti-Flyhack Violations",
                    "Anti-Noclip Violations",
                    "Anti-Speed Violations",
                    "Anti-Barrier Violations",
                    "Spinbot",
                    "Spiderman",
                    "Smaller Hitbox",
                    "Infinte Jump",
                    "Walk on Water",
                    "Turn Placeables",
                    "Increase Height",
                    "No Fall",
                    "No Slowdown",
                    "No Blackout",
                    "No Collision",
                    "Sign Painter",
                    "Instant Loot",
                    "Instant Revive",
                    "Instant Drink",
                    "Instant Med",
                    "Instant Med Friend"
                  ]
            },
            {
                "title": "COMBAT",
                "items": [
                    "Recoil",
                    "Full Auto",
                    "Fast Fire",
                    "No Sway",
                    "No Spread",
                    "Increase Velocity",
                    "Can Shoot",
                    "Spoof Hit distance",
                    "Thick Bullet",
                    "Weapon Spammer",
                    "No Bolt Cycle",
                    "Instant Eoka",
                    "Unlock Angles",
                    "Aim in Mountable",
                    "No Melee Slowdown",
                    "No Melee Punch",
                    "2x Melee Distance",
                    "Hammer Spam",
                    "No Aimcone",
                    "Fast Bow",
                    "Instant Charge",
                    "No Movement Penalty"
                  ]
            },
            {
                "title": "GAME VISUALS",
                "items": [
                    "Day Hack",
                    "Sky Changer",
                    "Ambient",
                    "Bright Stars",
                    "FOV Changer",
                    "Zoom",
                    "Draw Colliders",
                    "Hardcore Map",
                    "Hardcore Compass",
                    "Custom Melee Effect",
                    "Disable Grass/Weather Effects",
                    "No Server UI (Admins can't crash game)"
                  ]
            },
            {
                "title": "PLAYER LIST",
                "items": [
                    "Shows all players in the server",
                    "Shows players coordinate on Map",
                    "Displays if the server has Cerberus protection"
                  ]
            },
            {
                "title": "CONFIG",
                "items": [
                    "Save/Load Legit Config",
                    "Save/Load Rage Config",
                    "Menu Theme Color",
                    "Hotkey to Switch Configs"
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "SPOOOFER INCLUDED"},
            {"other":"Must purchase 400$ invite + month first after that can purchase month renew for 150$"}
        ],
    }, 
    {
        name: "Rust-External",
        game: "Rust",
        image: rustdisconnect,
        seopath: 'Rust-External',
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Rust-External",
        media: [
            {"url": "https://www.youtube.com/embed/BI15Jzod3hY?si=asJ5Ubz8cagPv-U3"},
        ],
        day: "8",
        week: "30",
        month: "60",
        features: [
            {
                "title": "WEAPON",
                "items": [
                    "Silent Aim",
                    "Aimbot Smoothing",
                    "Aimbot",
                    "Target Team",
                    "Hitchance %",
                    "Draw FOV",
                    "Target Line",
                    "Fire Speed",
                    "Always Automatic",
                    "Insant Eoka",
                    "Instant Bow",
                    "Shotgun Nospread",
                    "Weapon Sliders",
                    "Recoil X",
                    "Recoil Y",
                    "Spread",
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Player ESP",
                    "Show Team",
                    "Show Wounded",
                    "Show Sleeper",
                    "Show Ghost",
                    "Name ESP",
                    "Box (Full, Corner)",
                    "Healthbar (Side, Bottom, Top)",
                    "Distance (Side, Top, Bottom, Name)",
                    "Chams ",
                    "Bounding Box",
                    "Skeleton",
                    "World ESP",
                  ]
                  
            },
            {
                "title": "MISC",
                "items": [
                    "No Fall Damage",
                    "Infinite Jump",
                    "Spiderman",
                    "Flyhack (Toggle, Hold)",
                    "Fakelag (Toggle, Always)",
                    "Suicide ",
                    "Admin Flags",
                    "Zoom",
                    "FOV Changer",
                    "Time Changer",
                    "Bright Night",
                    "Long Neck",
                    "Remove Layer",
                    "Settings",
                    "Shadow Intensity/Size",
                    "Menu Accent Color",
                    "Watermark",
                    "Crosshair",
                    "Disable crosshair while aiming",
                    "Configs",
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "Streamproof"},
        ],
    },

    {
        name: "Recoil Club Script",
        game: "Rust",
        image: RustRecoilClub,
        seopath: 'recoil-club-script',

        status: "Undetected",
        stripe: "https://fivestar.sellhub.cx/product/Recoil-Club",
         media: [
            {"img": RustRecoilClub},
        ],
        week: "10",
        month: "25",
        features: [
            {
                "title": "FEATURES",
                "items": [
                    "Flawless recoil control for all weapons & attachments",
                    "Supports both old & new recoil",
                    "X & Y control amount",
                    "Auto detection for all weapons & scopes & Barrels",
                    "Smart detection",
                    "Customizable randomization",
                    "Customizable keybinds",
                  ]
            },
            {
                "title": "Features",
                "items": [
                    "Unload on key",
                    "Hip-fire control",
                    "Automatically detects game settings",
                    "Anti AFK",
                    "Auto fire",
                    "Auto Fish",
                    "Configuration system",
                    "Supports Multiple Languages"
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },
    // {
    //     name: "Rustsense",
    //     game: "Rust",
    //     image: rustrustsense,
    //     seopath: 'Rustsense-Script',

    //     status: "Undetected",
    //     stripe: "https://fivestar.cheating.store/product/Rustsense-Script",
    //      media: [
    //         {"img": rustrustsense},
    //     ],
    //     week: "12",
    //     month: "30",
    //     life: "60",
    //     features: [
    //         {
    //             "title": "FEATURES",
    //             "items": [
    //                 "Flawless recoil control for all weapons & attachments",
    //                 "Supports both old & new recoil",
    //                 "X & Y control amount",
    //                 "Auto detection for all weapons & scopes",
    //                 "Smart detection (improves weapon detection consistency and performance by remembering what was previously scanned)",
    //                 "Customizable randomization & humanization",
    //                 "Customizable keybinds (for cycling weapons, scopes & muzzles)",
    //                 "Configuration system (customizable configs)",
    //                 "Hip-fire control",
    //                 "Empty box tool",
    //                 "Unload on key (leaves no traces)",
    //               ]
    //         },
    //         {
    //             "title": "SECURITY",
    //             "items": [
    //                 "Unique client & module builds created every day for each user",
    //                 "Protection from client-side script check tools",
    //                 "Runs in the background",
    //                 "Completely hidden from task manager & processes",
    //                 "Doesn't interact with the game in any way",
    //                 "User information encrypted",
    //                 "Compatible with hardware-id spoofers",
    //                 "Automated client login"
    //               ]
    //         },
    //     ],
    //     requirments: [
    //         {"card": "NVIDIA & AMD"},
    //         {"winver": "WINDOWS 10/11"},
    //     ],
    // },
    // {
    //     name: "Accounts",
    //     game: "Rust",
    //     image: rustaccounts,
    //     seopath: 'Accounts',

    //     status: "Undetected",
    //     stripe: "https://fivestar.sellhub.cx/product/Rust-Accounts",
    //     media: [
    //         { img: rustaccounts },

    //       ],
    //     fullAccess: "15",
    //     partialAccess: "4",
    //     features: [
    //         {
    //             "title": "INCLUDES",
    //             "items": [
    //                 "15$ Full Access Rust Account"
    //               ]
    //         },
    //         {
    //             "title": "NFA",
    //             "items": [
    //                 "4$ Temporary Rust Account includes a 7 hour warranty"
    //               ]
    //         },
    //     ],
    //     requirments: [
    //         {"other": "Steam Installed"},
    //     ],
    // },
// {
//         name: "Last Cheat V3",
//         game: "Rust",
//         image: rustlastcheat,
//         seopath: 'Cpro',

//         status: "Updating",
//         stripe: "https://fivestar.cheating.store/product/LastCheat-V3",
//         media: [
//             { img: rustlastcheat },

//           ],
//         day: "7",
//         week: "35",
//         month: "65",
//         features: [
//             {
//                 "title": "AIMBOT",
//                 "items": [
//                     "On / Off",
//                     "Bullet que",
//                     "Instant hit",
//                     "Prediction Dot",
//                     "Target Snapline",
//                     "Ignore FOV",
//                     "Hitboxes",
//                     "Hit Chance",
//                     "FOV",
//                     "Manipulation",
//                     "Desync Bar",
//                     "Auto Shoot",
//                     "Wait For Desync",
//                     "Auto Reload",
//                     "Reload Bar",
//                     "One Tap Eoka",
//                     "No Spread",
//                     "Pierce",
//                     "Fast Bullet",
//                     "No Invalid Thick Bullet",
//                     "Bullet Tracers",
//                     "Recoil Slider",
//                     "Bullet TP",
//                     "Bullet Queue",
//                     "Water Reflect",
//                     "Desync",
//                     "Stationary Desync Amount (Manip)",
//                     "Constant Desync Amount (Manip)",
//                     "Ignore Scientists",
//                     "Ignore Team",
//                     "Ignore Sleepers",
//                     "Ignore Disconnected",
//                     "Ignore Dead",
//                     "Ignore Patrol"
//                   ]
//             },
//             {
//                 "title": "VISUALS",
//                 "items": [
//                     "Players",
//                     "Name",
//                     "Distance",
//                     "Weapon Text",
//                     "Flags",
//                     "Team ID",
//                     "Steam ID",
//                     "Hotbar Text",
//                     "Hotbar Images",
//                     "Clothing Text",
//                     "Clothing Images",
//                     "Skeleton",
//                     "Healthbar",
//                     "Box",
//                     "3D Box",
//                     "SnapLines",
//                     "Color options",
//                     "Chams",
//                     "Visibility Checks",
//                     "World Visuals",
//                     "Cargoship",
//                     "Patrol Helicopter",
//                     "Dropped Item",
//                     "Corpse",
//                     "Minicopter",
//                     "Car",
//                     "Boat",
//                     "Submarine",
//                     "Scrap Helicopter",
//                     "Locked Crate",
//                     "Sam Site",
//                     "Auto Turret",
//                     "Hemp",
//                     "Stone Ore",
//                     "Sulfur Ore",
//                     "Metal Ore",
//                     "Stashes",
//                     "Tool Cupboard"
//                   ]
//             },
//             {
//                 "title": "MISC",
//                 "items": [
//                     "Anti-Flyhack-Kick",
//                     "Anti-Flyhackbar",
//                     "Spiderman + Nofall",
//                     "Infinite Jump",
//                     "Admin Flags",
//                     "Block Server Commands",
//                     "Always sprint",
//                     "Always attack",
//                     "Spinbot",
//                     "Gunshot Spammer",
//                     "Walk On Water",
//                     "Walk Through Trees",
//                     "Walk Through Players",
//                     "Auto-Upgrade",
//                     "Hammer Spam",
//                     "Hammer Hit Anything",
//                     "Auto Pickup",
//                     "Auto Farm",
//                     "Bindable Noclip",
//                     "Bindable DebugCamera",
//                     "Ambient"
//                   ]
//             },
//         ],
//         requirments: [
//             {"card": "NVIDIA & AMD"},
//             {"winver": "WINDOWS 10/11"},
//             {"other": "SECURE BOOT & NO HYPERVISORS"},
//         ],
//     },
    
     //SPOOFERS
 {
    name: "Exception",
    game: "Spoofer",
    image: spooferexception,
    seopath: 'Exception-',

    status: "Undetected",
    stripe: "https://fivestar.cheating.store/product/Exception-Spoofer",
    media: [
        {"url": "https://www.youtube.com/embed/5a_fGQuX8Wk?si=oxivtrpHDqYqq4Xy"},
    ],
    day: "5",
    week: "13",
    month: "25",
    features: [
        {
            "title": "SPOOFS",
            "items": [
                "Disk",
                "USB",
                "Monitor",
                "GPU",
                "RAM",
                "MOTHERBOARD",
                "NetworkEFI",
            ],
        },
        {
                "title": "OTHER",
                "items": [
                "Anticheats: Easy Anti-Cheat, BattlEye Anti-Cheat, MRAC Anti-Cheat",
                "Supported Interfaces: NVMe (Non-RAID), SATA (AHCI)"
              ]
            
        }

    ],
    requirments: [
        {"card": "NVIDIA & AMD"},
        {"winver": " Windows 10-11 (19041-19045) (Home/Pro)"},
    ],
},
{
    name: "Perm-Spoofer",
    game: "Spoofer",
    image: spooferperm,
    seopath: 'Perm-Spoofer',

    status: "Undetected",
    stripe: "https://fivestar.sellhub.cx/product/Void-Perm-Spoofer",
    media: [
        {"img": permimg},
    ],
    day: "20",
    life: "80",
    features: [
        {
            "title": "SUPPORTS",
            "items": [
                "Laptops and Desktops.",
                "Intel / AMD",
                "Windows 10/11.",
                "Easy Anti-Cheat.",
                "Battle Eye.",
                "VANGAURD",
                "Fortnite (Including tournaments)"
              ]
            
        },
        {
            "title": "Supported Motherboards",
            "items": [
                "All Motherboards",
                "HP not supported",
              ]
            
        },

    ],
    requirments: [
        {"winver": "WINDOWS 10/11"},
        {"other": "Minimum 8 GB USB drive (for Windows reinstallation)"},
    ],
},
    //TARKOV
    {
        name: "ABS Terralabs",
        game: "Escape from Tarkov",
        image: tarkovterralabs,
        seopath: 'ABS-Terralabs',

        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/ABS-Terralabs",
        media: [
            {"img": tarkovterralabs},
        ],
        day: "8",
        days: "22",
        week: "50",
        month: "180",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Aim Prediction",
                    "Aim Key",
                    "Head Lock"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Player ESP",
                    "Scav ESP",
                    "Scav Boss ESP",
                    "SkeletonBox",
                    "Visibility Checks",
                    "Health ESP",
                    "Player Weapon + Ammo Count",
                    "Player ESP Distance",
                    "Item ESP",
                    "Medical",
                    "Food",
                    "Valuables",
                    "Keys",
                    "Task",
                    "Ammo",
                    "Attachments",
                    "Other",
                    "Container ESP",
                    "Grenade ESP",
                    "Extraction ESP",
                    "Crosshair"
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Streamproof",
                    "Night Vision",
                    "Thermal Vision",
                    "No Visor",
                    "Aim Warning"
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },
    {
        name: "Quantum Private",
        game: "Escape from Tarkov",
        image: tarkovquantumprivate,
        seopath: 'Quantum-private-eft',

        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Quantum-Private-EFT",
        media: [
            {"img": tarkovquantumprivate},
        ],
        day: "12",
        week: "35",
        month: "80",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "HWID Spoofer Included",
                    "Customizable",
                    "Low CPU Usage",
                    "Internal",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "ESP FEATURES",
                "items": [
                    "Player ESP",
                    "Scav ESP",
                    "Name ESP",
                    "Skeleton ESP",
                    "Box ESP",
                    "Distance ESP",
                    "Item ESP",
                    "Quest Items",
                    "Extraction ESP",
                    "Grenade ESP"
                  ],
            },
            {
                "title": "AIMBOT FEATURES",
                "items": [
                    "Aimbot",
                    "PSilent",
                    "Prediction",
                    "Aim Spot Changer",
                    "Aim key",
                    "Draw FoV",
                    "FoV Slider",
                    "Smoothing",
                    "Aim Distance",
                    "Aim Mode",
                    "Draw Crosshair"
                  ]
            },
            {
                "title": "RADAR FEATURES",
                "items": [
                    "Toggle",
                    "Radar background",
                    "Radar Outline",
                    "Radar Cross",
                    "Radar Zoom"
                  ]
            },
            {
                "title": "MEMEORY FERATURES",
                "items": [
                    "No Recoil",
                    "No Spread",
                    "No Sway",
                    "Instant Aim",
                    "No Gun Movement",
                    "Mag Drills",
                    "No Malfunctions",
                    "Run & Gun",
                    "Far Grenades",
                    "Fly Up/Down",
                    "Fly Level",
                    "Instant Examine",
                    "Infinite Search",
                    "Unlimited Oxygen",
                    "Unlimited Stamina",
                    "No Sprint Block",
                    "Loot Through Walls",
                    "Silent Loot",
                    "No Weight",
                    "No Inertia",
                    "Night Vision",
                    "No Visor",
                    "Day Hack",
                    "FoV Changer",
                    "High Jump"
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "SPOOFER INCLUDED"},
        ],
    },
    {
        name: "Coffee Lite",
        game: "Escape from Tarkov",
        image: tarkovcoffeelite,
        seopath: 'Coffee-eft-lite',

        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Coffee-EFT-Lite",
        media: [
            {"img": tarkovcoffeelite},
        ],
        day: "8",
        week: "40",
        month: "80",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "HWID Spoofer Included",
                    "Customizable",
                    "Low CPU Usage",
                    "Internal",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "ESP FEATURES",
                "items": [
                    "Player ESP",
                    "Scav ESP",
                    "Name ESP",
                    "Skeleton ESP",
                    "Box ESP",
                    "Distance ESP",
                    "Item ESP",
                    "Quest Items",
                    "Extraction ESP",
                    "Grenade ESP"
                  ],
            },
            {
                "title": "AIMBOT FEATURES",
                "items": [
                    "Aimbot",
                    "PSilent",
                    "Prediction",
                    "Aim Spot Changer",
                    "Aim key",
                    "Draw FoV",
                    "FoV Slider",
                    "Smoothing",
                    "Aim Distance",
                    "Aim Mode",
                    "Draw Crosshair"
                  ]
            },
            {
                "title": "RADAR FEATURES",
                "items": [
                    "Toggle",
                    "Radar background",
                    "Radar Outline",
                    "Radar Cross",
                    "Radar Zoom"
                  ]
            },
            {
                "title": "MEMEORY FERATURES",
                "items": [
                    "No Recoil",
                    "No Spread",
                    "No Sway",
                    "Instant Aim",
                    "No Gun Movement",
                    "Mag Drills",
                    "No Malfunctions",
                    "Run & Gun",
                    "Far Grenades",
                    "Fly Up/Down",
                    "Fly Level",
                    "Instant Examine",
                    "Infinite Search",
                    "Unlimited Oxygen",
                    "Unlimited Stamina",
                    "No Sprint Block",
                    "Loot Through Walls",
                    "Silent Loot",
                    "No Weight",
                    "No Inertia",
                    "Night Vision",
                    "No Visor",
                    "Day Hack",
                    "FoV Changer",
                    "High Jump"
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "SPOOFER INCLUDED"},
        ],
    },


    //Rainbow six
    {
        name: "R6 Lite",
        game: "Rainbow Six Siege",
        image: rainbowklar,
        seopath: 'R6-Lite',

        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/klar-R6-Lite",
        media: [
            {"img": rainbowklar},
        ],
        day: "5",
        week: "15",
        month: "30",
        features: [
            {
                "title": "ESP",
                "items": [
                    "HWID Spoofer Built in",
                    "[Enemies | Teammates | Hostage]",
                    "Skeleton ESP",
                    "Skeleton Outline",
                    "Skeleton Thickness Slider",
                    "Box ESP",
                    "Health ESP",
                    "Operater ESP",
                    "Playername",
                  ]
            },
            {
                "title": "GADGETS",
                "items": [
                    "Pings",
                    "Size Slider",
                    "Traps",
                    "Breach Utility",
                    "Observation",
                    "Shields",
                    "Objectives",
                    "Misc"
                  ]
            },
            {
                "title": "Weapon",
                "items": [
                    "Recoil Modifier",
                    "Spread Modifier",
                  ]
            },

        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },
    {
        name: "R6 Full",
        game: "Rainbow Six Siege",
        image: rainbowklar,
        seopath: 'r6-full',

        status: "Undetected",
        stripe: "https://fivestar.sellhub.cx/product/R6-Full",
        media: [
            {"img": rainbowklar},
        ],
        day: "10",
        week: "25",
        month: "50",
        features: [
            {
                "title": "ESP",
                "items": [
                    "Skeleton",
                    "[Enemies | Teammates | Hostage]",
                    "Skeleton ESP",
                    "Skeleton Outline",
                    "Skeleton Thickness Slider",
                    "Snaplines (Top, Bottom, Center)",
                    "Box ESP",
                    "Health ESP",
                    "Operater ESP",
                    "Playername",
                  ]
            },
            {
                "title": "GADGETS",
                "items": [
                    "Pings",
                    "Size Slider",
                    "Traps",
                    "Breach Utility",
                    "Observation",
                    "Shields",
                    "Objectives",
                    "Misc",
                  ]
            },
            {
                "title": "Visuals",
                "items": [
                    "FOV-Changer",
                    "3rd Person",
                    "Spectator List",
                    "No Smoke",
                    "No Shadows",
                    "No Hud",
                    "No Model",
                    "No Sky",
                    "No Paint",
                    "No colormask",
                  ]
            },
            {
                "title": "World",
                "items": [
                    "World-color-Changer",
                    "Rainbow-Mode",
                    "Color-Intensity",
                    "Skybox-Intensity",
                  ]
            },
            {
                "title": "Ragebot",
                "items": [
                    "Autowall",
                    "Shoot around Corners",
                    "Corner Angle",
                    "Corner Autowall",
                    "Time between Shots",
                    "Ragebot Frameskips",
                    "Corner Frameskips",
                    "Bullettracer",
                    "Hotkey-Mode",
                    "Hitbox-Mode",
                    "Ignore Head",
                  ]
            },
            {
                "title": "Legitbot",
                "items": [
                    "target Teammate",
                    "Visible Check",
                    "Draw FOV",
                    "FOV Circle Speed",
                    "FOV",
                    "Hotkey",
                    "Hitbox-Mode",
                    "Ignore Head",
                  ]
            },
            {
                "title": "Exploits",
                "items": [
                    "Force Rappel",
                    "EvilEye-Forcefire",
                    "Self-res",
                    "Noclip",
                    "Ladder Teleport",
                    "Fast / Insta Defuse",
                  ]
            },
            {
                "title": "Misc",
                "items": [
                    "Run&Shoot",
                    "Jumping",
                    "Ghosting",
                    "Ghosting-Indicator",
                    "Force Movement",
                    "Movement-Speed",
                    "No Spread",
                    "No Pullout-Delay",
                    "No Recoil",
                    "Longknife",
                    "Thickknife",
                    "Knife with Shield",
                    "Melee Aura / Autoknife",
                  ]
            },

        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },
    // {
    //     name: "Armycheats",
    //     game: "Rainbow Six Siege",
    //     image: rainbowarmy,
        
    //     status: "Undetected",
    //     stripe: "https://fivestar.cheating.store/product/Armycheats-R6-Lite",
    //     media: [
    //         {"url": "https://www.youtube.com/embed/i32N8eGkJbY?si=mEHZD-SIqsvXlIBN"},
    //     ],
    //     day: "8",
    //     week: "30",
    //     month: "60",
    //     features: [
    //         {
    //             "title": "ESP",
    //             "items": [
    //                 "Player ESP",
    //                 "HP",
    //                 "Name",
    //                 "Head Circle",
    //                 "Operator ESP",
    //                 "Tag ESP",
    //                 "Distance",
    //                 "Skeletons",
    //                 "Skeleton Visible/Hidden Color",
    //                 "World ESP",
    //                 "Gadget ESP",
    //                 "Drones",
    //                 "Cameras",
    //                 "Lethals",
    //                 "Stuns",
    //                 "Breaches",
    //                 "Utility"
    //               ]
    //         },
    //         {
    //             "title": "MISC",
    //             "items": [
    //                 "No Recoil",
    //                 "Spread Modifier",
    //                 "Spread Value",
    //                 "No Pullout"
    //               ]
    //         },

    //     ],
    //     requirments: [
    //         {"card": "NVIDIA & AMD"},
    //         {"winver": "WINDOWS 10/11"},
    //     ],
    // },
    {
        name: "XoR Procyon",
        game: "Valorant",
        image: valxorpro,
        seopath: 'XoR-Procyon',

        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/XOR-Procyclon",
        media: [
            {"img": valxorpro},
        ],
        day: "10",
        week: "25",
        month: "75",
        life: "200",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Aimbot Strength",
                    "3 Bone Selections",
                    "2 Keybinds for AimBot",
                    "Aimbot FOV",
                    "Visible Check",
                ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "FOV Changer",
                    "Skeleton",
                    "Distance",
                    "Radar",
                    "Box",
                    "Health Text",
                    "Health Bar",
                    "2D Box",
                    "2D Corner",
                    "Snaplines"
                ]
            },
            {
                "title": "MISC",
                "items": [
                    "Streamproof ON/OFF",
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10 ONLY"},
            {"other": "Auto updates"},
        ],
    },
    // {
    //     name: "XoR Alaska",
    //     game: "Valorant",
    //     image: valxoralaska,
    //     seopath: 'XoR-Alaska-Colorbot',

    //     status: "Undetected",
    //     stripe: "https://fivestar.cheating.store/product/XoR-Alaska-Colorbot",
    //     media: [
    //         {"img": valxoralaska},
    //     ],
    //     week: "20",
    //     month: "50",
    //     life: "150",
    //     features: [
    //         {
    //             "title": "AIMBOT",
    //             "items": [
    //                 "Aimbot",
    //                 "1 Keybind for Aimbot",
    //                 "Bone Selection",
    //                 "Aimbot Speed",
    //                 "Aimbot FOV",
    //                 "Recoil Speed",
    //                 "Recoil Distance",
    //             ]
    //         },
    //         {
    //             "title": "MISC",
    //             "items": [
    //                 "CONFIG SAVE",
    //                 "STREAMPROOF ON/OFF",
    //                 "COLOR SELECT (RED, YELLOW, PURPLE)",
    //               ]
    //         },
    //     ],
    //     requirments: [
    //         {"card": "INTEL & AMD"},
    //         {"winver": "WINDOWS 10 ONLY"},
    //         {"other": "FULLSCREEN SUPPORTED"},
    //     ],
    // },
    //Dayz
    // {
    //     name: "Intellisense",
    //     game: "Dayz",
    //     image: dayzintel,
        
    //     status: "Undetected",
    //     stripe: "https://fivestar.cheating.store/product/intellisense-DayZ",
    //     media: [
    //         {"img": dayzintel},
    //     ],
    //     day: "8",
    //     week: "25",
    //     month: "65",
    //     features: [
    //         {
    //             "title": "LEGIT TAB",
    //             "items": [
    //                 "Enable Aim [ & Hotkey ]",
    //                 "Smoothness Adjustment",
    //                 "Bone Pick [ Head, Chest, Pelvis, Random ]",
    //                 "Max Distance Adjustment",
    //                 "Snap Lines",
    //                 "FOV Adjustment [ Toggleable | Colored | Shape ]"
    //               ]
    //         },
    //         {
    //             "title": "RAGE TAB",
    //             "items": [
    //                 "Enable Silent Aim [ & Hotkey ]",
    //                 "Enable Targetting [ & Hotkey ]",
    //                 "Bone Pick [ Head, Chest, Pelvis, Random ]",
    //                 "Hitscan [ & Server Log Evasion ]",
    //                 "Max Distance Adjustment",
    //                 "FOV Adjustment [ Toggleable | Colored | Shape ]"
    //               ]
    //         },
    //         {
    //             "title": "MISC TAB",
    //             "items": [
    //                 "Adjust Day Time [ Sun Long/Lat, Local Time ]",
    //                 "No Grass",
    //                 "Unlock Thirdperson",
    //                 "Speedhack [ Hotkey ]",
    //                 "Teleport Loot [ Hotkey ]",
    //                 "Loot Through Walls / Freecam [ Hotkey ]",
    //                 "Customizable Speed [Faster/Slower] [ Hotkey ]",
    //                 "Customizable Movements [Left/Right/Forward/Backwards] [ Hotkey ]"
    //               ]
    //         },
    //         {
    //             "title": "VISUALS TAB",
    //             "items": [
    //                 "Automatic Admin Detection",
    //                 "Ignore Infected",
    //                 "Ignore Animals",
    //                 "Max Distance Adjustment",
    //                 "Max Item Distance Adjustment",
    //                 "Display Items [ Colored ]",
    //                 "Filter Items [ All, Weapon, Clothing, Ammo/Magazines, Tools, Building, Crafting, Food & More ]",
    //                 "Bounding Box [ Colored ]",
    //                 "Filled Box [ Colored ]",
    //                 "Cornered Box [ Colored ]",
    //                 "Head Hitbox [ Colored ]",
    //                 "Skeleton [ Colored ]",
    //                 "Entity Name [ Colored ]",
    //                 "Item In Hands [ Colored ]",
    //                 "Entity Type Name [ Colored ]",
    //                 "Distance [ Colored ]"
    //               ]
    //         },
    //         {
    //             "title": "CFG TAB",
    //             "items": [
    //                 "Crosshair [ Colored ]",
    //                 "Hide Watermark",
    //                 "Hide Visual Preview",
    //                 "Save/Load Config"
    //               ]
    //         },
    //     ],
    //     requirments: [
    //         {"card": "NVIDIA & AMD"},
    //         {"winver": "WINDOWS 10/11"},
    //     ],
    // },
    {
        name: "Klar-DayZ",
        game: "Dayz",
        image: KlarDayz,
        seopath: 'Klar-DayZ',

        status: "Undetected",
        stripe: "https://fivestar.sellhub.cx/product/Klar-DayZ",
        media: [
            {"img": KlarDayz},
        ],
        day: "7",
        week: "20",
        month: "40",
        features: [
            {
                "title": "AIM",
                "items": [
                    "Magic Bullet ( Patched on Officials )",
                    "Hitbox Selection                    ",
                    "Target Players",
                    "Target Infected",
                    "Draw FOV",
                    "FOV Slider",
                    "Aimbot Hotkey"
                  ]
            },
            {
                "title": "ESP",
                "items": [
                    "Skeleton ESP",
                    "Name ESP",
                    "Distance ESP",
                    "Display Held Items",
                    "Item ESP",
                    "Car ESP",
                    "Animal ESP",
                    "Towns ESP",
                    "Customizable Colors"
                  ]
            },
            {
                "title": "MISC TAB",
                "items": [
                    "FullBright",
                    "FullBright Slider",
                    "Remove Grass",
                    "FOV Changer",
                    "FOV Slider",
                    "Display Postion",
                    "Debug Camera ( Freecam / Loot)",
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"Other": "HWID Spoofer Built in"},
        ],
    },
   

    //CS2
    {
        name: "Fecurity",
        game: "Counter Strike 2",
        image: csgofecurity,
        seopath: 'Fecurity-CS',

        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/CS2-Fecurity",
        media: [
            {"url": "https://www.youtube.com/embed/thu_aaIjkG8?si=BHO6BeYtdKlSzqTg"},
        ],
        day: "4",
        week: "10",
        month: "17",
        features: [
            {
                "title": "AIMBOT GENERAL",
                "items": [
                    "Enable (Enables aimbot)",
                    "Aim at shoot",
                    "Visible Only (Aim for visible bones only)",
                    "Enemy only (Aim for enemies only)",
                    "Aim horizontal speed",
                    "Aim vertical speed",
                    "Recoil compensation"
                  ]
                
            },
            {
                "title": "SELECT",
                "items": [
                    "Draw FOV",
                    "FOV",
                    "Target switch delay",
                    "Hitscan coefficient"
                  ]
                
            },
            {
                "title": "BINDS",
                "items": [
                    "Hitbox priority",
                    "Aim key",
                    "Second aim key",
                  ]
            },
            {
                "title": "TRIGGER",
                "items": [
                    "Mode (Disable / Enable)",
                  ]
            },
            {
                "title": "VISUALS GENERAL",
                "items": [
                    "Visuals enabled",
                    "Enemy only (Visuals for enemies only)",
                  ]
            },
            {
                "title": "CHARACTER",
                "items": [
                    "Box (Draw player box)",
                    "Box outline",
                    "Health (Draw health bar)",
                    "Shield (Draw shield bar)",
                    "Skeleton (Draw skeleton)",
                    "Maximum distance",
                    "Player info (Nickname, Distance, etc)"
                  ]
                
            },
            {
                "title": "MISC",
                "items": [
                    "Auto jump",
                    "Auto pistol",
                    "Auto accept (Auto accepting matchmaking)",
                    "Add all skins (Intel only atm)"
                  ]
                
            },
            {
                "title": "UNTRUSTED POLICY",
                "items": [
                    "Untrusted mode (Enables untrusted mode)",
                    "No spread",
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "AVX & UEFI REQUIRED"},
        ],
    },

    //APEX
    // {
    //     name: "Fecurity",
    //     game: "Apex Legends",
    //     image: apexfecurity,
        
    //     status: "Undetected",
    //     stripe: "https://fivestar.cheating.store/product/Apex-Legends-Fecurity",
    //     media: [
    //         {"img": apexfecurity},
    //     ],
    //     day: "4",
    //     week: "20",
    //     month: "35",
    //     features: [
    //         {
    //             "title": "FEATURES",
    //             "items": [
    //                 "Grenade ESP",
    //                 "Smart loot",
    //                 "Player ESP",
    //                 "Health",
    //                 "Distance",
    //                 "Nickname",
    //                 "Skeleton",
    //                 "2D Box",
    //                 "FOV",
    //                 "Aim-Assist"
    //               ]
                
    //         },
    //     ],
    //     requirments: [
    //         {"card": "INTEL only"},
    //         {"winver": "WINDOWS 10/11"},
    //     ],
    // },
    {
        name: "Cola Apex",
        game: "Apex Legends",
        image: apexcola,
        seopath: 'Cola-Apex',

        status: "Updating",
        stripe: "https://fivestar.sellhub.cx/product/Cola-Apex",
        media: [
            {"img": apexcola},
        ],
        day: "5",
        week: "25",
        month: "50",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Aimbot",
                    "Customizable Lock Key",
                    "pSilent",
                    "Aim at Knocked",
                    "Triggerbot",
                    "Triggerbot Delay",
                    "Triggerbot Key",
                    "Selectable Aim Location",
                    "Aim Distance",
                    "FOV",
                    "Smoothing",
                    "pSilent Miss Percentage"
                  ]
            },
            {
                "title": "ESP",
                "items": [
                    "Box Type",
                    "Health",
                    "Distance",
                    "Name",
                    "Customizable Glow Color",
                    "Kill Count",
                    "Account Level",
                    "Adjustable Distance",
                    "Crosshair/FOV",
                    "Item Icon ESP",
                    "Adjustable Language",
                  ]
            },
            {
                "title": "LOOT",
                "items": [
                    "Item ESP",
                    "Draw Distance",
                    "Item Grade",
                    "Item Category",
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "No Spread",
                    "Auto Pistol",
                    "Instant Hit",
                    "Free Camera",
                    "No Sway",
                    "Auto Strafe",
                    "Auto Super Glide",
                    "Infinity Loba Shop",
                    "Recoil Scale",
                    "Auto Loot",
                    "Auto Loot Key",
                    "Auto Loot Prefer Shields",
                    "Auto Loot Filter",
                    "Grenade, Arc Star, and Knife prediction",
                    "Auto Grenade Explode",
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },

    //BATTLE
    {
        name: "Fecurity",
        game: "Battlebit",
        image: battlefecurity,
        seopath: 'Fecurity-Battlebit',

        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Battlebit-Fecurity",
        media: [
            {"img": battlefecurity},
        ],
        day: "6.50",
        week: "28",
        month: "50",
        features: [

            {
                "title": "VISUALS",
                "items": [
                    "Enabled - Visuals enabled",
                    "Enemy only - Visuals for enemies only",
                    "Box - Draw player box",
                    "Box outline",
                    "Health - Draw health bar",
                    "Skeleton - Draw skeleton",
                    "Maximum Distance - Distance for visuals, skeleton, box",
                    "Player info - Nickname, distance, etc",
                    "AIMBOT (ONLY SILENT)",
                    "Aimbot at shoot",
                    "Magic aimbot",
                    "Bind aimbot",
                    "MISC",
                    "Ovveride FOV - Overrides field of view",
                    "FOV",
                    "EXPLOITS",
                    "Speed hack",
                    "Instant hit",
                    "Instant ads",
                    "Unlock fire modes",
                    "No spread",
                    "No recoil",
                    "No boltcatch",
                    "No ballistic",
                    "DPI"
                  ]
                
            },

        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },

    //MW
    {
        name: "Stealth + Spoofer",
        game: "Modern Warfare",
        image: MWStealthfull,
        seopath: 'Modern-warfare-stealth',

        status: "Undetected",
        stripe: "https://fivestar.sellhub.cx/product/Stealth",
        media: [
            {"img": MWStealthfull},
        ],
        day: "6",
        week: "15",
        month: "30",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Aim Key / Controller Aim Key",
                    "Override Key.",
                    "Override Bone.",
                    "Aim Bone Selection.",
                    "Selectable Targeting Types",
                    "Normal Aim/Silent Aim.",
                    "Smoothing.",
                    "Miss Chance %.",
                    "Aim Distance Slider.",
                    "Auto Fire.",
                    "Auto Wall.",
                    "Adjustable Damage + Distance for Auto Wall.",
                  ]              
            },
            {
                "title": "Aim Settings",
                "items": [
                    "Visible Check",
                    "FOV Circle.",
                    "FOV Circle Size",
                    "FOV Circle Colour.",
                    "RGB",
                    "Enemies Only.",
                    "Aim at Downed.",
                    "Aim at Self Revivers.",
                    "Remove Recoil with Slider.",
                    "Custom Targeted Player Colour.",
                    "Preset Legit/Rage Settings Options.",
                  ]              
            },
            {
                "title": "Anti-Aim",
                "items": [
                    "Preset AA Options",
                    "Spinbot / Reverse Aim / Jitter",
                    "Custom AA Options",
                    "X Axis - Look Up / Look Down / Jitter / None",
                    "Y Axis - Spin / Reverse Aim / Jitter / None",
                    "Fake Lag Toggle with Custom Choke Threshold.",
                    "Vehicle AA.",
                    "Wheele/Endo Options.",
                    "Reverse Vehicle / Spin Vehicle.",
                  ]              
            },
            {
                "title": "VISUALS",
                "items": [
                    "Player Names.",
                    "Player Weapons.",
                    "Player Distance.",
                    "Player Health Bars.",
                    "Player Team IDs.",
                    "Loot ESP.",
                    "Customisable ESP Boxes.",
                    "Skeletons.",
                    "Customisable Snap Lines.",
                    "Radar Overlay.",
                    "Fully Customisable Radial Compass.",
                    "RGB Bullet Tracers.",
                  ]
            },
            {
                "title": "VISUAL SETTINGS",
                "items": [
                    "Enemies Only.",
                    "ESP Distance.",
                    "Radar Distance.",
                    "Compass Distance.",
                    "Compass Size.",
                    "Arrow Size.",
                    "Compass Visible Only Toggle.",
                    "ESP Boldness Slider.",
                    "Aim Warning Toggle.",
                    "Customisable Enemy FOV Warnings.",
                    "Customisable Warning Colours.",
                    "Show Player Warning Name Toggle.",
                    "Outlines/Chams.",
                    "Player Outlines.",
                    "Vehicle Outlines.",
                    "AI Outlines.",
                    "Self Outlines.",
                    "Draw Filled.",
                    "RGB Toggle for Outlines.",
                    "Draw Visible/Not Visible Entities.",
                    "RGB.",
                  ]
            },
            {
                "title": "SAVER",
                "items": [
                    "Meta Blueprint Savers.",
                    "MP/WZ Selectable Attachments.",
                    "Apply Rank 250 Charms Toggle for Blueprints.",
                    "Riveter/MORS Missile Attachments.",
                    "Perk Saver.",
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Loadout Name Changer.",
                    "Loadout Camo Changer.",
                    "Clantag Changer.",
                    "Account Name Profanity Bypass.",
                    "Unlock All.",
                  ]
            },
            {
                "title": "EXPLOITS",
                "items": [
                    "Super Sprint.",
                    "No Fog.",
                    "No Flash.",
                    "No Flinch.",
                    "Long Melee.",
                    "Fast Reload.",
                    "Third Person.",
                    "Dynamic Third Person.",
                    "Instant Weapon Pickup.",
                    "Rapid Fire.",
                    "Airlock/Air Stuck.",
                    "Custom Air Stuck Keybind / Controller Keybind Selector.",
                    "FOV Changer.",
                  ]    
            },
            {
                "title": "LOBBY",
                "items": [
                    "View Player Usernames.",
                    "Mass Report Players.",
                    "Chat Spam.",
                  ]
            },
            {
                "title": "SETTINGS",
                "items": [
                    "Language Selector.",
                    "5 Config Slots.",
                    "Loadable with Hotkeys.",
                    "Start up Config.",
                    "Custom ESP Colours.",
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "Modernwarfare + Warzone"},
        ],
    },
    {
        name: "Fecurity",
        game: "Modern Warfare",
        image: mwfecurity,
        seopath: 'Fecurity-Battlebit-',

        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Modernwarfare-Fecurity",
        media: [
            {"img": mwfecurity},
        ],
        day: "4",
        week: "12",
        month: "25",
        months: "55",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Enable.",
                    "Aim at Shoot.",
                    "Aim at Scope.",
                    "Visbile only.",
                    "Two types of aimbot input. (Relative, absolute).",
                    "Controllable speed (0-100%).",
                    "Recoil compensation.",
                    "Draw FOV.",
                    "Gamepad Support (Only xbox at the moment).",
                    "Prediction.",
                    "Setupable FOV (0-15).",
                    "Ignore Knocked Targets.",
                    "Target Switch Delay.",
                    "Absolutely unique hitbox system.",
                    "Two aim keys."
                  ]
                
            },
            {
                "title": "VISUALS",
                "items": [
                    "Enable.",
                    "Enemy only.",
                    "Box.",
                    "OOF indicators (out of view).",
                    "Health.",
                    "Shield.",
                    "Skeleton.",
                    "Skeleton Thickness.",
                    "Glow.",
                    "Fill Glow.",
                    "Visibility check.",
                    "Glow thickness.",
                    "Type (Default, textured, thermal, noisy).",
                    "Distance.",
                    "Player Info (Nickname, distance, weapon, ping, kills, rank)."
                  ]
            },
            {
                "title": "LOOT",
                "items": [
                    "Enable.",
                    "Distance.",
                    "Categories (Other, ammo, weapon, armor, money, self, booster, gasmask, killstreak, tactical)."
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Unlocker.",
                    "Custom Clantag.",
                    "Distance Unit (Feet, yard, meter)."
                  ]
            },
        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "ONLY UEFI"},
        ],
    },
    {
        name: "Fecurity UNLOCKER",
        game: "Modern Warfare",
        image: mwfecurityunlocker,
        seopath: 'Fecurity-Modern-warfare-unlocker',

        status: "Use at own risk",
        stripe: "https://fivestar.cheating.store/product/Unlocker-Tool-MW",
        media: [
            {"img": mwfecurityunlocker},
        ],
        month: "15",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Unlocks everything so you can play with anything you want.",
                  ]
            },

        ],
        requirments: [
            {"card": "NVIDIA & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "ONLY UEFI"},
        ],
    },

    //VALORANT
    // {
    //     name: "Coffee Full",
    //     game: "Valorant",
    //     image: valcoffee,
        
    //     status: "Updating",
    //     stripe: "https://fivestar.cheating.store/product/Coffee-Valorant",
    //     media: [
    //         {"img": valcoffee},
    //     ],
    //     day: "10",
    //     week: "50",
    //     month: "90",
    //     features: [
    //         {
    //             "title": "AIMBOT",
    //             "items": [
    //                 "Aim FOV",
    //                 "Crosshair",
    //                 "Aim Bones (Nearest)"
    //             ]
    //         },
    //         {
    //             "title": "VISUALS",
    //             "items": [
    //                 "ESP Font Size",
    //                 "Player BOX",
    //                 "Player Healthbar",
    //                 "Player Name",
    //                 "Player Weapon",
    //                 "Player Agent",
    //                 "Player Skeleton",
    //                 "Player Glow",
    //                 "Glow Intensity",
    //                 "Visibility Check"
    //             ]
    //         },
    //         {
    //             "title": "MISC",
    //             "items": [
    //                 "CONFIG SYSTEM",
    //               ]
    //         },
    //     ],
    //     requirments: [
    //         {"card": "NVIDIA & AMD"},
    //         {"winver": "WINDOWS 10/11"},
    //         {"other": "ONLY UEFI"},
    //     ],
    // },
    // {
    //     name: "Fecurity",
    //     game: "Valorant",
    //     image: valfecurity,
        
    //     status: "Undetected",
    //     stripe: "https://fivestar.cheating.store/product/Fecurity-Valorant",
    //     media: [
    //         {"img": valfecurity},
    //     ],
    //     day: "12",
    //     days: "30",
    //     week: "60",
    //     month: "80",
    //     features: [
    //         {
    //             "title": "FEATURES",
    //             "items": [
    //                 "Player ESP",
    //                 "Health",
    //                 "Armor",
    //                 "Distance",
    //                 "Nickname",
    //                 "Skeleton",
    //                 "Aimbot",
    //                 "Smooth",
    //                 "No recoil",
    //                 "2D Box",
    //                 "FOV",
    //                 "Chams",
    //                 "Weapon ESP",
    //                 "TriggerBOT",
    //                 "Spread Prediction for Trigger",
    //                 "Trigger Crosshair",
    //                 "Shield Checkbox",
    //                 "Trigger Crosshair customization",
    //                 "Trigger delay working by MS"
    //             ]
    //         },

    //     ],
    //     requirments: [
    //         {"card": "NVIDIA & AMD"},
    //         {"winver": "WINDOWS 1809/11"},
    //     ],


    // },
   
]
