import './App.css';
import React, { createContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Navbar } from './components/navbar.js';
import { Footer } from './components/footer.js';
import { Home } from './pages/home.js';
import { Products } from './pages/products.js';
import { Product } from './pages/product.js';
import { Faq } from './pages/faq.js';
import { Tos } from './pages/tos.js';
import { Unknown } from './pages/unknown.js';
import { Status } from './pages/status.js';
import { Logo } from './images/logo.webp';

export const AppContext = createContext(false);

const BlockedPage = () => {
  return <Navigate to="/blocked" />;
};

// User agent check function
const isBlockedUserAgent = () => {
  const blockedUserAgents = [
    /almaden/i,
    /Anarchie/i,
    /ASPSeek/i,
    /attach/i,
    /autoemailspider/i,
    /BackWeb/i,
    /Bandit/i,
    /BatchFTP/i,
    /BlackWidow/i,
    /Bot\ mailto:craftbot@yahoo.com/i,
    /Buddy/i,
    /bumblebee/i,
    /CherryPicker/i,
    /ChinaClaw/i,
    /CICC/i,
    /Collector/i,
    /Copier/i,
    /Crescent/i,
    /Custo/i,
    /DA/i,
    /DIIbot/i,
    /DISCo/i,
    /DISCo\ Pump/i,
    /Download\ Demon/i,
    /Download\ Wonder/i,
    /Downloader/i,
    /Drip/i,
    /DSurf15a/i,
    /eCatch/i,
    /EasyDL\/2.99/i,
    /EirGrabber/i,
    /email/i,
    /EmailCollector/i,
    /EmailSiphon/i,
    /EmailWolf/i,
    /Express\ WebPictures/i,
    /ExtractorPro/i,
    /EyeNetIE/i,
    /FileHound/i,
    /FlashGet/i,
    /FrontPage/i,
    /GetRight/i,
    /GetSmart/i,
    /GetWeb!/i,
    /gigabaz/i,
    /Go\!Zilla/i,
    /Go!Zilla/i,
    /Go-Ahead-Got-It/i,
    /gotit/i,
    /Grabber/i,
    /GrabNet/i,
    /Grafula/i,
    /grub-client/i,
    /HMView/i,
    /HTTrack/i,
    /httpdown/i,
    /httrack/i,
    /ia_archiver/i,
    /Image\ Stripper/i,
    /Image\ Sucker/i,
    /Indy*Library/i,
    /Indy\ Library/i,
    /InterGET/i,
    /InternetLinkagent/i,
    /Internet\ Ninja/i,
    /InternetSeer.com/i,
    /Iria/i,
    /JBH*agent/i,
    /JetCar/i,
    /JOC\ Web\ Spider/i,
    /JustView/i,
    /larbin/i,
    /LeechFTP/i,
    /LexiBot/i,
    /lftp/i,
    /Link*Sleuth/i,
    /likse/i,
    /Link/i,
    /LinkWalker/i,
    /Mag-Net/i,
    /Magnet/i,
    /Mass\ Downloader/i,
    /Memo/i,
    /Microsoft.URL/i,
    /MIDown\ tool/i,
    /Mirror/i,
    /Mister\ PiX/i,
    /Mozilla.*Indy/i,
    /Mozilla.*NEWT/i,
    /Mozilla*MSIECrawler/i,
    /MS\ FrontPage*/i,
    /MSFrontPage/i,
    /MSIECrawler/i,
    /MSProxy/i,
    /Navroad/i,
    /NearSite/i,
    /NetAnts/i,
    /NetMechanic/i,
    /NetSpider/i,
    /Net\ Vampire/i,
    /NetZIP/i,
    /NICErsPRO/i,
    /Ninja/i,
    /Octopus/i,
    /Offline\ Explorer/i,
    /Offline\ Navigator/i,
    /Openfind/i,
    /PageGrabber/i,
    /Papa\ Foto/i,
    /pavuk/i,
    /pcBrowser/i,
    /Ping/i,
    /PingALink/i,
    /Pockey/i,
    /psbot/i,
    /Pump/i,
    /QRVA/i,
    /saveweb2zip/i,
    /RealDownload/i,
    /Reaper/i,
    /Recorder/i,
    /ReGet/i,
    /Scooter/i,
    /Seeker/i,
    /Siphon/i,
    /sitecheck.internetseer.com/i,
    /SiteSnagger/i,
    /SlySearch/i,
    /SmartDownload/i,
    /Snake/i,
    /SpaceBison/i,
    /sproose/i,
    /Stripper/i,
    /Sucker/i,
    /SuperBot/i,
    /SuperHTTP/i,
    /Surfbot/i,
    /Szukacz/i,
    /tAkeOut/i,
    /Teleport\ Pro/i,
    /URLSpiderPro/i,
    /Vacuum/i,
    /VoidEYE/i,
    /Web\ Image\ Collector/i,
    /Web\ Sucker/i,
    /WebAuto/i,
    /[Ww]eb[Bb]andit/i,
    /webcollage/i,
    /WebCopier/i,
    /Web\ Downloader/i,
    /WebEMailExtrac.*/i,
    /WebFetch/i,
    /WebGo\ IS/i,
    /WebHook/i,
    /WebLeacher/i,
    /WebMiner/i,
    /WebMirror/i,
    /WebReaper/i,
    /WebSauger/i,
    /Website/i,
    /Website\ eXtractor/i,
    /Website\ Quester/i,
    /Webster/i,
    /WebStripper/i,
    /WebWhacker/i,
    /WebZIP/i,
    /Wget/i,
    /Whacker/i,
    /Widow/i,
    /WWWOFFLE/i,
    /x-Tractor/i,
    /Xaldon\ WebSpider/i,
    /Xenu/i,
    /Zeus.*Webster/i,
    /Zeus/i
  ];
  const userAgent = navigator.userAgent;
  return blockedUserAgents.some(regex => regex.test(userAgent));
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  if (isBlockedUserAgent()) {
    return <Navigate to="/blocked" />;
  } else {
    return <Component {...rest} />;
  }
};

function App() {
  const [mobileActive, setMobileActive] = useState(false);

  return (
    <div className="App">
      <Router>
        <AppContext.Provider value={[mobileActive, setMobileActive]}>
          <Navbar />
          <Routes>
            <Route path="/" element={<ProtectedRoute component={Home} />} />
            <Route path="/products" element={<ProtectedRoute component={Products} />} />
            <Route path="/products/:gameUrl/:cheatUrl" element={<ProtectedRoute component={Product} />} />
            <Route path="/status" element={<ProtectedRoute component={Status} />} />
            <Route path="/faq" element={<ProtectedRoute component={Faq} />} />
            <Route path="/tos" element={<ProtectedRoute component={Tos} />} />
            <Route path="/blocked" element={<BlockedPage />} />
            <Route path="/*" element={<ProtectedRoute component={Unknown} />} />
          </Routes>
        </AppContext.Provider>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
