import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Link, Route, useLocation } from 'react-router-dom';
import { Footer } from '../components/footer.js'
import { data } from '../components/cheats-data.js';
import { Games } from '../components/games.js';

export const Products = () => {
   const [activeFilter, setActiveFilter] = useState("All Games");
   const [searchInput, setSearchInput] = useState("");

   const location = useLocation();

   useEffect(() => {
      // Parse query parameters to set the active filter when the component mounts
      const params = new URLSearchParams(location.search);
      const category = params.get("category");

      if (category) {
         setActiveFilter(decodeURIComponent(category));
      }
   }, [location.search]);

   const handleChange = (e) => {
      e.preventDefault();
      setSearchInput(e.target.value);
   };

   const Test = () => {
      console.log(activeFilter);
   }

   const setFilter = event => {
      setActiveFilter(event.target.value);
      console.log(activeFilter);
   }

   const sanitizeForURL = (str) => {
      // Regular expression to match any character that is not a letter, number, underscore, or hyphen
      const invalidCharRegex = /[^a-zA-Z0-9_\-]/g;

      // Replace invalid characters with an empty string
      return str.replace(invalidCharRegex, '');
   };

   const filterCheat = (cheat) => {
      if (searchInput.length <= 0) {
         return activeFilter === "All Games" ? cheat.game != null : cheat.game === activeFilter
      } else if (activeFilter === "All Games") {
         return cheat.name.toLowerCase().match(searchInput.toLowerCase());
      } else {
         return cheat.game === activeFilter && cheat.name.toLowerCase().match(searchInput.toLowerCase());
      }
   }


   const getGameURL = (cheatGame) => {
      return sanitizeForURL(cheatGame);
   };

   const getCheatURL = (cheatName) => {
      return sanitizeForURL(cheatName);
   }

   const getLowestPrice = (filteredCheat) => {
      let prices = Array.from([
         filteredCheat.fullAccess,
         filteredCheat.partialAccess,
         filteredCheat.day,
         filteredCheat.days,
         filteredCheat.week,
         filteredCheat.weeks,
         filteredCheat.month,
         filteredCheat.months,
         filteredCheat.life,
         filteredCheat.special,
      ]);
      let filteredPrices = prices.map((v) => v || Number.MAX_SAFE_INTEGER);
      return Math.min(...filteredPrices);
   }

   return (
      <>
         <div className="products">
            <div className="products-title">PRODUCTS</div>
            <div className="filters">
               <select className="filter" name="filter" value={activeFilter} onChange={setFilter} placeholder="Filter games... ">

                  <option value="All Games">All Games</option>
                  {Games.map((game) => {
                     return <option value={game.game}>{game.game}</option>
                  })}

               </select>
               <div className="searchbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3l-1.4 1.4ZM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14Z" /></svg>
                  <input type="search" className="search" name="search" placeholder="Search Cheats..."
                     onChange={handleChange}
                     value={searchInput}>
                  </input>
               </div>
            </div>

            <div className="cheats">
               {data.filter((cheat) => filterCheat(cheat)).map((filteredCheat) => {
                  return (
                     <div className="game-card">
                        <div className="card-img"><img src={filteredCheat.image} /></div>
                        <div className="card-info">
                           <div className="card-text">
                              <div className="card-cheat-container"><span className="card-cheat">{filteredCheat.game} | {filteredCheat.name}</span></div>
                              <div className="text-purchase">
                                 <div className="price-container">
                                    <span className="card-start">Starting at</span>
                                    {filteredCheat.special != null ?
                                       <span className="card-price">${filteredCheat.special}</span>
                                       :
                                       <span className="card-price">${getLowestPrice(filteredCheat)} </span>
                                    }
                                 </div>

                                 <div className="btn-border">
                                    <Link to={getGameURL(filteredCheat.game) + "/" + getCheatURL(filteredCheat.name)}>
                                       <div className="btn">
                                          VIEW
                                       </div>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  )
               })
               }
            </div>
         </div>


      </>

   )

}